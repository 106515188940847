<template>
  <Card class="zero-card">
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div>
          <h4>İlanlar</h4>
        </div>
        <div  class="bg-success p-3 rounded-sm" >
           <button>
            <b-icon icon="file-earmark-pdf" aria-hidden="true"></b-icon>
           </button>  
        </div>
      </div>
    </template>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
      Toplam {{ totalItems }} müşteri bulundu.
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input">
        <Input placeholder="Ara.." v-model="search" class="mr-2" />
      </div>
      <b-button
        variant="outline-primary"
        class="d-flex d-md-inline-block mt-0 w-md-auto w-100 mb-6"
        v-b-toggle.sidebar-right
        >Filtrele</b-button
      >
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <router-link :to="`find/detail/${item.id}`">
            <button class="btn btn-sm btn-light">
              <i class="flaticon2-next text-dark p-0"></i>
            </button>
          </router-link>
        </template>
        <template v-slot:item.varlik="{ item }">
          <div class="badges-area">
            <div class="badges">
              <i
                class="badge"
                v-for="(badges, key) in item.badges"
                :key="key"
                :class="[badgesEnum[key]]"
              >
              </i>
            </div>

            <p>{{ item.varlik }}</p>
          </div>
         
        </template>

        <template v-slot:item.activeStatus="{ item }">
          <Steps
            :steps="3"
            :activeStep="item.activeStatus"
            :stepNames="stepNames"
          />
        </template>
        <template v-slot:item.tapu="{ item }">
          <div class="tapu-text">
            <h6 style="font-size: 14px">{{ item.tapu }}</h6>
            <h6 style="font-size: 12px">{{ item.ilanBilgileri }}</h6>
            <span style="font-size: 11px"
              >tapu: <strong>{{ item.tapuCount }}</strong> | Taşınmaz:
              <strong>{{ item.tasinmazCount }}</strong>
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex mb-5">
            <router-link
              :to="`declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                :href="href"
                @click="navigate"
                > <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
              </b-button>
            </router-link>
            
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
      <b-sidebar id="sidebar-right" right>
        <div
          class="card dveb-gray-card aside-gray-card tab-card"
          style="overflow-y: auto"
        >
          <div class="card-header">FİLTRELE</div>
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
              <div class="form-group">
                <label for="sahip">Sahibi</label>
                <select name="" id="sahip" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Şekerbank A.Ş</option>
                </select>
              </div>

              <div class="form-group">
                <label for="durum">Durumu</label>
                <select name="" id="durum" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="il">Şehir</label>
                <select name="" id="il" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="ilce">İlçe</label>
                <select name="" id="ilce" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>

              <div class="row">
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Temizle </b-button>
                </div>
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Uygula </b-button>
                </div>
                <div class="col-12 mt-4">
                  <b-button variant="light" class="w-100">
                    Bu Filtreyi Kaydet
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
              <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4>Filtre Örnek</h4>
                  <hr />
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4"> Uygula </b-button>
                    <b-button
                      variant="danger"
                      class=""
                      @click="openDeleteHandler(key)"
                    >
                      Sil
                    </b-button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-sidebar>
    </div>
    <DeleteOperation />
  </Card>
</template>

<style lang="scss" scoped>

@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
.ilan-alert {
  &.active {
    background-color: rgb(179, 26, 26);
    color: white;
    // padding: 1px;
    border-radius: 10px;
    width: 188px;
    font-size: 11px;
    text-align: center;
    margin-top: 10px;
  }
  &.active:hover {
    background-color: rgb(147, 31, 31);
    transition: 0.2s;
    cursor: default;
  }
}
</style>

<script>
export default {
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames : ["Hazırlık","Satış","Devir"],
      header: [
        { text: "", value: "id", size: "50px" },
        { text: "Varlık", value: "varlik" },
        { text: "Sahibi", value: "tapu" },
        { text: "Tapu Bilgileri", value: "" },
        { text: "Statü", value: "activeStatus" },
        { text: "İşlemler", value:  "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList:[]
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          varlik: " DENEME SEKERBANK DEViR",
          ilanBilgileri: "ŞEKERBANK T.A.Ş.",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          ilanStatus:1,
          tasinmazCount: 0,
          activeStatus: 2,
          badges: [1, 2, 3],
        },
        {
          id: 2,
          varlik: "TEST-05.07.2023",
          ilanBilgileri: "ING BANK A.Ş.",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          ilanStatus:1,
          tasinmazCount: 0,
          activeStatus: 2,
          badges: [1, 2],
        },
        {
          id: 3,
          varlik: "TEST-05.07.2023",
          ilanBilgileri: "ING BANK A.Ş.",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          ilanStatus:1,
          tasinmazCount: 0,
          activeStatus: 3,
          badges: [1, 2],
        },
        {
          id: 4,
          varlik: "TEST-05.07.2023",
          ilanBilgileri: "ING BANK A.Ş.",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          ilanStatus:0,
          tasinmazCount: 0,
          activeStatus: 4,
          badges: [1, 2],
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: this.items.length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show('modal-deleteOperation');
      let index = this.notes.indexOf(key)
      this.notes.splice(index,1)
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
};
</script>
